@import '../../styles/abstracts/mixins';

.header {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100%;
  margin-top: 2rem;
  background-color: var(--base-white);
  box-shadow: 0 24px 20px -20px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  height: 8rem;
  padding: 0 2rem;
  box-sizing: border-box;
  &__mobile-back-button {
    display: none;
  }

  &__mobile-back-button > .btn {
    padding: 1.1rem;
  }

  &__label {
    position: relative;
    margin-left: 0.7rem;
    margin-bottom: 1.3rem;
    @include breakpoint(sm) {
      margin-left: 0.2rem;
      margin-bottom: 0.7rem;
    }
  }
  &.landing{
    @include breakpoint(sm) {
      margin-bottom: 0!important;
    }
  }
  @include breakpoint(sm) {
    margin-bottom: 2rem;
  }

  @include breakpoint(sm) {
    height: 7.4rem;
    padding: 0;
  }

  &__logo-container {
    display: flex;
    align-self: center;
    align-items: flex-end;
    padding-left: 1.5rem;
    position: relative;

    span { color: var(--moss-green); }
  }
  &__logo-image {
    cursor: pointer;
  }

  &__logo-text {
    margin-left: 1rem;
    @include breakpoint(sm) {
      display: none;
    }
  }


  &__nav-container {
    display: grid;
    grid-template-columns: 8rem auto repeat(2, min-content);
    align-items: center;
    justify-self: flex-end;
    height: 100%;
    width: 100%;

    &.flex {
      display: flex;
      justify-content: flex-end;
    }

    @include breakpoint(sm) {
      margin-right: 1.3rem;
      grid-template-columns: 1fr repeat(2, min-content);
      width: 100%;
    }

    button { width: auto; }
  }
}

