@import '../../styles/abstracts/mixins';

.preview {
  padding: 5rem 4rem 4rem 7rem;
  overflow: auto;
  @include breakpoint(sm) {
    height: 84vh;
    padding: 2rem;
    overflow-y: auto;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: flex-start;

    button {
      width: 18rem;
      @include breakpoint(sm) {
        max-width: 14rem;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__content {
    width: 75%;
    .location-content {
      width: 100%;
      display: flex;
      img {
        border-radius: 0.5rem;
        margin-right: 5rem;
      }

      &__table {
        tbody  {
          tr {
            display: flex;
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
          }

          td {
            &:first-child {
              width: 15rem;
            }
          }
        }
      }

      &__info-container {
        border: 1px solid var(--moss-green-light);
        box-sizing: border-box;
        border-radius: 0.5rem;
        padding: 4rem 4rem 5.5rem 4rem;
      }

      &__date-picker_wrapper {
        display: flex;
        align-items: center;
        margin-top: 4rem;
        margin-bottom: 5rem;
      }

      &__date-picker {
        min-width: 24rem;
        max-width: 24rem;
        margin-right: 4rem;

        .input-container {
          max-width: 100%;
          margin: 0;
        }
      }

      &__package_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 5rem;
      }

      &__package-item {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 3rem;
        }
      }

      &__price-list {
        padding-bottom: 2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__bar {
    width: 20%;
    align-self: flex-start;  
    display: flex;
    flex-direction: column;

    &--packages {
      width: 100%;
      text-align: left;
    }

    &--prices {
      width: 100%;
      padding-bottom: 3rem;
      h3 {
        width: 100%;
        text-align: left;
        color: var(--moss-green);
      }
    }

    &--price {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &:last-child {
        padding-top: 4rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    &--button {
      margin-top: 5rem;
      width: 18rem;
    }
  }
}

.package-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .number-input {
    margin: 0;
  }

  &__cell {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--end {
    padding-bottom: 5rem;
    border-bottom: 1px solid var(--moss-green-light);
  }
}

.price-list-table {
  width: 100%;
  
  // .base-value {}

  .base-price {
    width: 15%;
  }

  .base-step {
    width: 15%;
  }

  .base-amount {
    width: 15%;
    text-align: right;
  }

  .header-padding {
    padding-bottom: 5rem;
  }
}

.extras-table {
  width: 100%;

  .name {}

  .price {
    width: 10%;
  }

  .amount {
    width: 15%;
  }

  .last {
    .number-input {
      margin: 0;
    }
  }
}

.amount-input-box {
  display: flex;
  justify-content: flex-end;
}

.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__buttons {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin-bottom: 2rem;
    position: relative;

    &--submit {
      width: 100%;

      button {
        width: 100%;
        @include breakpoint(sm) {
          max-width: 100%;
        }
      }
    }

    &--checkbox {
      margin-bottom: 2rem;
    }
  }

  &__menu {
    position: absolute;
    left: 0;
    z-index: 5;

    width: 100%;
    background-color: var(--base-white);
    padding-bottom: 3rem;
    box-shadow: 0px 24px 20px -20px rgba(0, 0, 0, 0.15);
    transition: top 0.5s;
  }

  &__location-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
    border-radius: 0.5rem;
    img {
      border-radius: 0.5rem;
      width: 100%;
      height: 100%;
    }
  }

  &__table-info {
    width: 100%;

    tr {
      &:not(:last-child) {
        p {
          margin-bottom: 0.8rem;
        }
      }
    }
  }

  &__package-info {
    width: 100%;
    padding: 3rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h1 {
      text-align: center;
    }
  }

  &__package-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }

  &__package-content-item {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &:last-child {
      margin-bottom: 4.5rem;
    }
  }

  &__price-list-info {
    width: 100%;
    padding: 3rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__box {
      width: 100%;
    }

    &__table {
      width: 100%;

      td {
        width: 33%;
        padding-bottom: 2rem;
        &:last-child {

          .amount-input-box {
            margin-top: 2rem;
          }

          .number-input {
            margin: 0;
          }
        }
      }
    }
  }

  &__extras-info {
    padding-top: 3rem;
    width: 100%;

    &__table {
      width: 100%;

      tr:last-child  {
        td:last-child {
          .number-input {
            margin: 0;
          }
        }
      }

      td {
        width: 33%;
      }
    }
  }
}