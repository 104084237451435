@import '../../styles/abstracts/mixins';

.block {
    &__title {
        text-align: center;
        color: var(--moss-green-dark);
    }

    &__subtitle {
        text-align: center;
        padding-top: 1.5rem;
        white-space: nowrap;
        @include breakpoint(sm) {
            white-space: initial;
        }
    }

    &__description {
        text-align: center;
        padding-top: 3.4rem;
        font-size: 1.2rem
    }

    &__content-success {
        margin: 7.5rem auto 7.6rem;
        @include breakpoint(sm) {
            margin: 5rem auto 10rem;
        }
    }

    &__registration-btn {
        width: 23.2rem;
        flex-direction: row;
        @include breakpoint(sm) {
            width: 100%;
        }
      }

    &__span {
        white-space: nowrap;
    }

    @include breakpoint(sm) {
        width: 100%;
        padding: 2rem;
    }
}
