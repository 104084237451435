// TODO collect all identical styles for identical side-bars here

.side-bar {
  &__active {
    background-color: var(--pewter-grey);
  }

  &__un-viewed-count {
    min-width: 2.4rem;
    min-height: 2.4rem;
    border-radius: 50%;
    background-color: var(--deer-gold);
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: var(--base-white)
    }
  }
}