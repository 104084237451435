html {
	font-size: 62.5% !important;
	height: -webkit-fill-available;
	
	@include breakpoint(lg) {
		font-size: 56.25% !important;
	}
	@include breakpoint(md) {
		font-size: 50% !important;
	}
	@include breakpoint(sm) {
		font-size: 62.5% !important;
	}
}

body {
	// overflow: hidden;
	background-color: #eee;
	background-image: url("../../assets/images/backgroundDesktop.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	@include breakpoint(sm) {
		background-image: url("../../assets/images/backgroundMobile.png");
	}
}

.container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	padding: 0 2rem;
}

.hunter-onboarding-container {
	max-width: max-content;
  margin: 0 auto;
}
