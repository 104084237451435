.avatar {
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  overflow: hidden;

  &__no-avatar {
    background-color: var(--moss-green-light);
  }

  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}