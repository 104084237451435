.range-picker {
  &__picker-input {
    align-self: center;
    justify-self: center;
  }
}

.piker-wrapper {
  width: 100%;
}

.piker-popper-wrapper {
  margin-top: -1rem !important;
  background: var(--base-white);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  .piker {
    font-family: Ubuntu;
    font-size: 1.5rem;
    border: none;
    border-radius: 1rem;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      background-color: var(--base-white);
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
      border: none;

      .react-datepicker__current-month {
        margin-bottom: 1rem;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: bold;
        line-height: 150%;
        color: var(--text-color);
      }
    }

    .react-datepicker__month {
      margin: 0.4rem 1.5rem;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      margin: 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: inherit;
      line-height: 150%;
      opacity: 0.75;
      color: var(--text-color);

      &:hover {
        color: var(--text-color);
        background-color: var(--base-white);
      }
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
      color: var(--deer-gold);
      background-color: var(--base-white);
    }

    .react-datepicker__day--disabled {
      color: var(--moss-green);

      &:hover {
        color: var(--moss-green);
      }
    }
  }
}
