:root {
	--base-white: #fff;
	--base-black: #000;
	--base-red: #e11;

	// from figma
	--deer-gold: #BF8F60;
	--deer-gold-light: #F3ECE0;
	--moss-green-dark: #2C3831;
  --moss-green: #AEB8AF;
  --moss-green-lighter: #D3E3D5;
	--pewter-grey: #EBEBE8;
	--moss-green-light: #D3E3D5;
	--boar-meat: #D00B2F;
	--button-inactive: #CBC1B7;
	--text-color: #2C3831;
	--background-secondary: #fbfbfb;
}
