.text-area-wrapper {
  width: 100%;
  height: 13rem;
  position: relative;

  &__text-area {
    height: 100%;
    width: 100%;
    resize: none;
    background-color: var(--pewter-grey);
    padding: 1.6rem 2rem 1.6rem 2rem;
    border: none;
    border-radius: 5px;
    color: var(--deer-gold);
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    box-sizing:border-box;

    &-error {
      border: 1px solid var(--boar-meat);
    }
  }

  &__label {
    font-family: Ubuntu, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    margin-left: 20px;
    align-self: center;
  }

  &__error-span {
    position: absolute;
    left: 0;
    bottom: -17%;
  }
}
