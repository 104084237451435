@mixin breakpoint($point) {
	@if $point == lg {
		// 1200 px
		@media (max-width: 75em) {
			@content;
		}
	} @else if $point == md {
		// 900 px
		@media (max-width: 56.25em) {
			@content;
		}
	} @else if $point == sm {
		// 600 px
		@media (max-width: 37.5em) {
			@content;
		}
	} @else if $point == xs {
		// 400px
		@media (max-width: 25em) {
			@content;
		}
	}
}
