@import '../../styles/abstracts/mixins';

.nav {
  &__mobileMenu {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.dropdown {
  display: flex;

  &__btn {
    height: 100%;
    border-radius: 0;
    text-align: center;

    &.login {
      border-radius: 3rem;
      box-shadow: 0 24px 20px -20px var(--deer-gold);
    }
  }

  &__icon {
    padding: 1rem;
    height: 100%;
    justify-content: flex-end;
  }

  &__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 16rem;
    max-height: 0;
    border-radius: 1rem;
    background: var(--base-white);
    box-shadow: 0 24px 20px -20px rgba(0, 0, 0, 0.15);
    right: 0;
    top: 120%;
    z-index: 9;
    overflow: hidden;
    transition: all 0.3s ease;

    &.login {
      width: 15rem;
      border-radius: 2.4rem;
      box-shadow: 0 24px 20px -20px var(--deer-gold);
      top: 220%!important;
      right: -3rem !important;

      @include breakpoint(md) {
        top: 220%;
        right: 50%;
      }
    }

    @include breakpoint(md) {
      top: 290%;
      right: -4rem;
    }

    &-wrapper {
      position: relative;
      padding: 3.2rem 1rem;
      white-space: nowrap;

      &.login {
        padding: 0;
      }

      @include breakpoint(md) {
        padding: 0;
      }
    }

    &-btn {
      svg {
        transition: all 0.3s ease;
      }

      &:hover {
        svg {
          opacity: 0.7;
        }
      }
    }

    &.open {
      max-height: 50rem;
      height: auto;
      padding-bottom: 1rem
    }
    &.open.login {
      padding-bottom: 0;
    }
  }
}

.dropdown__link {
  padding: 1.6rem 1rem;
  max-width: fit-content;
  z-index: 1;
  cursor: pointer;

  &:first-of-type.login {
    display: none;
  }

  &:last-of-type.login {
    margin-bottom: 0;
  }

  &.login {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    background: var(--deer-gold-light);
    margin-bottom: 0.1rem;
  }

  & > a {
    text-decoration: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.hidden {
    display: none;
  }
}

.text.login {
  color: var(--deer-gold)
}
