.callout {
  font-family: 'Cormorant', serif;
  font-size: 5.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 5.5rem;
  letter-spacing: 0;
  color: var(--text-color);
}

.m-callout {
  font-family: 'Cormorant', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 110%;
}

.title {
  font-family: 'Cormorant', serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.8rem;
  letter-spacing: 0.02em;
  color: var(--text-color);
}

.m-title {
  font-family: 'Cormorant', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: var(--text-color);
}

.subtitle {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text-color);
}

.paragraph {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.3rem;
  letter-spacing: 0.01em;
  color: var(--text-color);
}

.sm-paragraph,
.paragraph-light {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 2.3rem;
  color: var(--text-color);
  font-weight: 300;
}

.lg-paragraph {
  font-family: 'Ubuntu', serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8rem;
}

.m-paragraph {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  color: var(--text-color);
}

.sm-paragraph {
  font-size: 1.2rem;
  font-style: normal;
}

.highlight {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0;
  color: var(--text-color);
}

.text {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--text-color);
}

.text-sans {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--text-color);
}

.m-caption {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem;
  letter-spacing: 0.02em;
  color: var(--text-color);
}

.lg-caption {
  font-family: 'Cormorant', serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.8rem;
  color: var(--text-color);
  text-transform: uppercase;
}

.mobile {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8rem;
  letter-spacing: 0.02em;
  color: var(--text-color);
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100%);
  overflow: hidden;
  display: inline-block;
}

.gold {
  color: var(--deer-gold);
}

.meat {
  color: var(--boar-meat);
}

.moss-green {
  color: var(--moss-green);
}