.block {
  box-sizing: border-box;
  background-color: var(--base-white);
  padding: 6rem 11.8rem 9rem 11.7rem;
  border-radius: 1rem;
  max-width: 575px;
  margin: 5.4rem auto 5.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(lg) {
    padding: 6rem;
  }
  @include breakpoint(sm) {
    //margin-top: 2rem;
    margin-top: 0;
    border-radius: 0;
    background-color: transparent;
  }

  > * {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(md) {
      width: 100%;
    }
  }

  &_md {
    padding: 6rem 4.2rem 9rem;
  }

  &_lg {
    padding: 0;
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    // height: calc(100vh - 14rem);
    margin: 2rem auto;
    background-color: #fff;
    box-shadow: 0px 24px 20px -20px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    overflow: hidden;

    @include breakpoint(sm) {
      height: auto;
      padding-bottom: 3rem!important;
      margin: 0 auto;
    }
  }
}
