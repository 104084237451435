.modal-wrapper {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 8;
	background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	box-sizing: border-box;

	padding: 5rem 6.5rem;
	background-color: var(--base-white);

	box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
	border-radius: 1rem;

	&__closeIcon {
		position: absolute;
		top: -9px;
    right: -9px;
		cursor: pointer;
		z-index: 100;
	}
}