.btn {
  width: 100%;
  border: none;
  border-radius: 5px;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 1rem;
  box-sizing: border-box;

  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--base-white);

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  &__oval {
    border-radius: 3rem;
  }
}

.primary {
  background: var(--deer-gold);

  &:hover {
    background: var(--base-white);
    box-shadow: 0 24px 20px -20px var(--deer-gold);
    color: var(--deer-gold);

    path {
      fill: var(--deer-gold);
    }

    .postFix_arrow {
      path {
        fill: white;
        stroke: var(--deer-gold);
      }
    }
  }

  &:disabled {
    background: var(--button-inactive);

    &:hover {
      .postFix_arrow {
        path {
          fill: transparent;
          stroke: var(--deer-gold);
        }
      }
    }
  }
}

.secondary {
  border: 1px solid var(--deer-gold);
  background: transparent;
  color: var(--deer-gold);
  padding: 1.5rem 1rem;
  path {
    fill: var(--deer-gold);
  }

  &:hover {
    background: var(--base-white);
    box-shadow: 0 24px 20px -20px var(--deer-gold);
    color: var(--deer-gold);
  }

  &:disabled {
    background: var(--button-inactive);
    border: none;
    color: var(--base-white);
    path {
      fill: var(--base-white);
    }
  }
}

.toggle {
  background: none;
  color: var(--deer-gold);
  border-radius: 0;
  padding-bottom: 8px;
}

.toggleActive {
  background: none;
  color: var(--base-black);
  border-radius: 0;
  border-bottom: 2px solid var(--deer-gold);
  padding-bottom: 8px;
}

.menu {
  background: none;
  color: var(--moss-green-dark);
  justify-content: start;
  padding: 1.5rem 1.8rem;
  text-align: start;
  position: relative;
  &:hover {
    background: var(--pewter-grey);
  }
}

.postFix_icon {
  margin-left: 1rem;
  height: 18px;
  width: 16px;
}

.postFix_arrow {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.postFix_arrow_down_gold {
  margin-left: 0.5rem;
  align-items: center;
  width: 13px;
  height: 8px;
}

.prefix_arrow_left {
  margin-right: 2rem;
}

.icon {
  background: none;
  border: none;
}
