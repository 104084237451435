// overwrite gallery styles 

.overwrite-gallery {
  width: 100%;

  .image-gallery-slide {
    border-radius: 0.5rem;
  }

  .image-gallery-image {
    // max-height: 36rem !important;
    border-radius: 0.5rem;
    // object-fit: cover !important;
  }

  .image-gallery-thumbnails-wrapper {
    margin-top: 3rem;
  }

  .image-gallery-thumbnail-image {
    max-height: 9rem;
  }
}

.image-gallery-slide div .image-gallery-image {
  max-width: 100%;
  width: auto;
}