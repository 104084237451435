.mb-20 {
  margin-bottom: 2rem !important;
}

.mt-20 {
  margin-top: 2rem !important;
}

.mb-40 {
  margin-bottom: 4rem !important;
}

.mt-40 {
  margin-top: 4rem !important;
}

.mt-45 {
  margin-top: 4.5rem !important;
}

.mt-30 {
  margin-top: 3rem !important;
}

.mb-30 {
  margin-bottom: 3rem !important;
}

.mt-10 {
  margin-top: 1rem !important;
}

.mb-10 {
  margin-bottom: 1rem !important;
}

.cursor-pointer {
  cursor: pointer;

  &:hover td {
    background: var(--pewter-grey);
  }
}