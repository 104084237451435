$border-radius: 5px;
$border-color: var(--moss-green-light);
$table-background-color: var(--background-secondary);

.list-table {
  width: 100%;
  overflow: auto;
  // general styles
  table {
    border-spacing: 0;
    width: 100%;
    border-collapse: separate;
    table-layout: auto;
  }
  th, td {
    font-family: Ubuntu, sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  th {
    padding: 1.5rem 3rem;
    text-align: left;
  }
  td {
    border: 1px solid $border-color;
    padding: 1.4rem 3rem;
    background: $table-background-color;
    a {
      color: var(--deer-gold);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  // rounded corners
  tbody tr:first-child td:first-child {
    border-top-left-radius: $border-radius;
  }
  tbody tr:first-child td:last-child {
    border-top-right-radius: $border-radius;
  }
  tbody tr:last-child td:first-child {
    border-bottom-left-radius: $border-radius;
  }
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: $border-radius;
  }

  // inner borders
  tbody tr:not(:last-child) td {
    border-bottom: none;
  }
  td:not(:first-child):not(:last-child) {
    border-left: none;
    border-right: none;
  }
  td:first-child {
    border-right: none;
  }
  td:last-child {
    border-left: none;
  }
  .pagination {
    padding: 1rem;
    &>*{
      margin: 0.5rem;
      padding: 0.5rem;
    }
  }
}

.striped:nth-child(odd)  {
  td {
    background-color: var(--pewter-grey);
  }
}

.table-last-column {
  text-align: right !important;
}

.table-one-column {
  td {
    border: 1px solid var(--moss-green-light) !important;
  }
}

.table-first-column-with-badge {
  position: relative;
  margin-left: 2.4rem;

  .badge {
    position: absolute;
    top: 18%;
    left: -3rem;
  }
}