.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-self: flex-start;

  .input-wrapper {
    position: relative;
  }

  &__label {
    font-family: Ubuntu, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    margin-left: 20px;
    align-self: center;
  }

  &__checkbox {
    appearance: none;
    background-color: var(--pewter-grey);
    height: 18px;
    width: 18px;
    border-radius: 5px;
  }
  &__checkbox:active, &__checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0 1px 3px rgba(0,0,0,0.1);
  }
  &__checkbox:checked {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0 -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    color: #99a1a7;
  }
  &__checkbox:checked:after {
    padding-left: 3px;
    padding-bottom: 20px;
    content: '\2714';
    font-size: 14px;
    position: absolute;
    color: var(--deer-gold);
  }

  &__error {
    border: 1px solid #e11;
  }
}
