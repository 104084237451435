.input-container {
  height: 4.8rem;
  width: 100%;
  margin-bottom: 2rem;

  &__primary-container {
    display: flex;
    height: 100%;
    background: var(--pewter-grey);
    border-radius: 5px;
    border: none;
    padding: 3px 16px 3px 16px;
    box-sizing: border-box;
    align-items: center;
  }

  &__primary-password {
    display: flex;
    height: 100%;
    background: var(--pewter-grey);
    border-radius: 5px;
    border: none;
    padding: 3px 16px 3px 16px;
    box-sizing: border-box;
  }

  &__primary-input {
    color: var(--deer-gold);
    background: var(--pewter-grey);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
    line-height: 16px;
    border: none;
    box-shadow: none;
    width: 95%;
    height: 100%;
  }

  &__secondary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: var(--base-white);
    border-radius: 5px;
    border: 1px solid var(--moss-green);
    padding: 3px 16px 3px 16px;
    box-sizing: border-box;
  }

  &__secondary-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: var(--base-white);
    border-radius: 5px;
    border: 1px solid var(--moss-green);
    padding: 3px 16px 3px 16px;
    box-sizing: border-box;
  }

  &__secondary-input {
    width: 100%;
    height: 100%;
    color: var(--deer-gold);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
    line-height: 16px;
    border: none;
    box-shadow: none;
  }

  &__secondary-gray {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--pewter-grey);
    border-radius: 5px;
    padding: 3px 16px 3px 16px;
  }

  &__secondary-input-gray {
    width: 100%;
    height: 100%;
    color: var(--base-black);
    background: var(--pewter-grey);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
    line-height: 16px;
    border: none;
    box-shadow: none;
    margin-bottom: 10px;
  }

  &__icon-button {
    height: 24px;
    background: none;
    border: none;
    align-self: center;
    padding: 0;
  }

  &__search-black-input {
    color: var(--base-black);
    background: var(--pewter-grey);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
    line-height: 16px;
    border: none;
    box-shadow: none;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
  }

  &__search-gold-input {
    color: var(--deer-gold);
    background: var(--pewter-grey);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
    line-height: 16px;
    border: none;
    box-shadow: none;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
  }

  &__search_button {
    width: 20px;
    height: 17px;
    background: none;
    border: none;
    align-self: center;
    margin-right: 1.5rem;
  }

  &__label {
    margin-top: 6px;
    color: var(--moss-green);
    font-family: "Ubuntu", sans-serif;
  }

  &__error {
    border: 1px solid var(--boar-meat);
  }

  &__error-span {
    color: var(--boar-meat);
    font-weight: 100;
    font-size: 12px;
    line-height: 12px;
    margin-top: 2px;
    margin-left: 16px;
  }

  input:disabled,
  &__disabled {
    background: var(--button-inactive);
    cursor: not-allowed;
    color: var(--text-color);
  }
}

// input[type="date"] {
//   -moz-appearance: textfield; /* Firefox */
// }

input[type="date"] {
  // background: transparent;
  color: var(--deer-gold);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bf8f60" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');

  // color: var(--deer-gold);
  // filter: invert(100%);
}

.number-input {
  height: 4.8rem;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;

  &__sm {
    max-width: 10.1rem;
  }

  &__secondary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--base-white);
    border-radius: 5px;
    border: 1px solid var(--moss-green);
    box-sizing: border-box;

    input {
      text-align: center;
      width: 46%;
      color: var(--deer-gold);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      font-family: "Ubuntu", sans-serif;
      line-height: 16px;
      border: none;
      box-shadow: none;
    }
  }

  &__buttons {
    height: 100%;
    width: 27%;
    display: flex;
    align-items: center;

    &--plus {
      justify-content: flex-start;
    }
    &--minus {
      justify-content: flex-end;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  &__error-popup {
    position: absolute;
    top: 110%;
    left: 0;
    color: var(--boar-meat);
  }

  &__disabled {
    background-color: var(--pewter-grey);
  }

  &__error {
    border: 1px solid var(--boar-meat);
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
