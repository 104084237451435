.CalendarDay__selected_span {
  background: var(--deer-gold) !important; //background
  color: white !important; //text
}

.CalendarDay__selected_span:hover {
  background: var(--deer-gold) !important; //background
  color: white !important; //text
  // border: 1px solid red !important; //default styles include a border
}

// // Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: var(--deer-gold) !important;
  color: white !important;
}

// // Will edit when hovered over. _span style also has this property
// .CalendarDay__selected:hover {
//   background: lightsalmon !important;
//   color: white !important;
// }

// // Will edit when the second date (end date) in a range of dates
// // is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--deer-gold) !important;
  color: white !important;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.CalendarDay {
  border: 0px !important;
  vertical-align: middle !important;
}

.DayPicker_weekHeader {
  top: 54px !important;
}

.DayPickerNavigation_button {
  border: none !important;
}

.DateInput_input {
  border: none !important;
  padding: 0 !important;
}

.DateInput_input__focused {
 // border-bottom: 2px solid var(--deer-gold) !important;
}

.DateRangePickerInput_arrow_svg {
  width: 50px !important;
}

.CalendarMonth_caption {
  font-weight: bold;
}

.DateRangePickerInput_arrow {
  display: none !important;
}

.DateInput_input,
.DateInput_input__focused {
  width: calc(100% - 2rem) !important;
  padding-left: 2rem !important;
  font-family: 'Ubuntu' !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
  color: #AEB8AF !important;
  opacity: 0.6;
}

.DateInput_input {
  opacity: 1;
  color: #BF8F60 !important;
}

.DateRangePickerInput__withBorder {
  font-size: 1.6rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 43px;
}

.DateRangePicker {
  width: 100% !important;
}

.DateRangePicker_picker {
  z-index: 5 !important;
}

