@import "../../styles/abstracts/mixins";

.dialog-modal {
  text-align: center;
  &__title {
    text-align: center;
    margin-bottom: 5rem;
    margin-top: 1rem;
  }

  &__text-area {
    margin-bottom: 2rem;
  }

  .btn_group {
    display: flex;
    justify-content: space-between;
    button {
      width: 19rem;
      @include breakpoint(sm) {
        width: 10rem;
      }
    }
  }
}
